import React, {useState, useEffect} from "react";
import commonHelper from "../../../helper/common_helper";
import sublimationsService from "../../../services/sublimations-service";
import commonService from "../../../services/common-service";
import ConfirmDiallog from "../../../shared/ConfirmDiallog";
import SnackAlert from "../../../shared/SnackAlert";
import Spinner from "../../../shared/Spinner";
import AddSublimation from "./AddSublimation";
import SublimationsView from "./SublimationsView";

export default function AdminSublimations() {
    const [spinner, setSpinner] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [btn, setBtn] = React.useState('Add Sublimation');
    const [editId, setEditId] = React.useState();
    const [inputField, setInputField] = useState({});
    const [images, setImages] = useState([]);
    const [sublimations, setSublimations] = useState([]);
    const [searched, setSearched] = useState("");
    const [rows, setRows] = useState([]);


    useEffect(()=>{
        getSublimations();
    },[])

    const getSublimations=async() => {
        setSpinner(true);
        const res = await commonService.get_record({table_name: 'sublimations'});
        setSublimations(res.data);
        setRows(res.data);
        setSpinner(false);
    }

    
    const handleChange=(e) => {    
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        let formData = new FormData();
        if(btn === 'Add Sublimation'){
            if(images.length === 0){
                setMsg('Please Select image');
                setSeverity('error');
                setAlert(true);
                return;
            }
            formData = commonHelper.createFormData(formData,inputField);
            for (let i = 0; i < images.length; i++) {
                formData.append(`sub_image[${i}]`, images[i])
            }
            const res = await sublimationsService.addSublimation(formData);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setSublimations(res.data);
                setRows(res.data);
                Object.keys(inputField).forEach(key => delete inputField[key]);
                setImages([]);
                setOpen(false);
                setMsg('Sublimation added successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }else{
            let obj = {};
            obj['id'] = editId;
            obj['sub_name'] = inputField.sub_name;
            obj['sub_description'] = inputField.sub_description;
            formData = commonHelper.createFormData(formData,obj);
            for (let i = 0; i < images.length; i++) {
                formData.append(`sub_image[${i}]`, images[i])
            }
            const res = await sublimationsService.editSublimation(formData);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setSublimations(res.data);
                setRows(res.data);
                Object.keys(inputField).forEach(key => delete inputField[key]);
                setImages([]);
                setOpen(false);
                setMsg('Sublimation updated successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }
        setBtn('Add Sublimation')
        setSpinner(false);
    }

    const resetFields = () => {
        Object.keys(inputField).forEach(key => delete inputField[key]);
        setImages([]);
    }

    const delItem=async()=>{
        setSpinner(true);
        const res = await commonService.del_record({id: editId, table_name: 'sublimations'});
        if(res.data.status){
            setSublimations(sublimations.filter(function( obj ) {
                return obj.id !== editId;
            }))
            setRows(sublimations.filter(function( obj ) {
                return obj.id !== editId;
            }));
        }
        setSpinner(false);
        setOpenDialog(false);
        setMsg('Sublimation deleted successfuly')
        setSeverity('success')
        setAlert(true)
    }

    const editItem=async(item)=>{
        console.log(item)
        setSpinner(true);
        setInputField(item);
        setOpen(true);
        setSpinner(false);
        setBtn('Update Sublimation');
        setEditId(item.id);
    }


    const fileSelectedHandler = (e) => {
        setImages(e.target.files)
    }

    const requestSearch = (searchedVal) => {
        const filteredRows = rows.filter((row) => {
          return row.sub_name.toLowerCase().includes(searchedVal.toLowerCase())
          || row.sub_description.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setSearched(searchedVal);
        setSublimations(filteredRows);
    };

    return (
        <div>
            <SublimationsView 
                sublimations = {sublimations}
                inputField = {inputField}
                searched = {searched}

                handleOpen = {() => {setOpen(true); resetFields()}}
                delItem = {(id) => {setEditId(id); setOpenDialog(true)}}
                editItem = {(item) => editItem(item)}
                requestSearch = {(e)=>requestSearch(e)}
            />
            <AddSublimation
                open = {open}
                inputField = {inputField}
                btn = {btn}
                
                fileSelectedHandler = {(e)=>fileSelectedHandler(e)}
                handleClose = {() => {setOpen(false); setBtn('Add Sublimation')}}
                handleChange = {(e)=>handleChange(e)}
                handleSubmit = {(e)=>handleSubmit(e)}
            />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
            <ConfirmDiallog
                openDialog = {openDialog}

                closeDialog = {()=>setOpenDialog(false)}
                delItem = {()=>delItem()}
            />
        </div>
    );
}
