import React, {useState} from 'react';
import { Button } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';

const ScrollButton = () =>{
	const [visible, setVisible] = useState(false)

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 300){
		setVisible(true)
		}
		else if (scrolled <= 300){
		setVisible(false)
		}
	};

	const scrollToTop = () =>{
		window.scrollTo({
		top: 0,
		behavior: 'smooth'
		/* you can also use 'auto' behaviour
			in place of 'smooth' */
		});
	};

	window.addEventListener('scroll', toggleVisible);

	return (
		<Button onClick={scrollToTop} className="btn-blue"
			sx={{
				display: visible ? 'inline' : 'none',
				position: 'fixed',
				bottom: '20px',
				right: '30px',
				padding: 0,
				minWidth: '35px',
				borderRadius: 0,
				fontWeight: 'bold'
				}}>
			<KeyboardArrowUp/>
		</Button>
	);
}

export default ScrollButton;
