import * as React from 'react';
import {Grid, List,ListItem,ListItemButton,Typography,ListItemText,Paper,Divider,Button, IconButton} from '@mui/material';
import {Facebook,Instagram} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import chamber from '../../../../assets/images/img/chamber.jpg'
import {router} from '../../../../routePaths/routePaths';
import Logo from "../../../../assets/images/logo/logo_footer.png";

export default function FooterView(props) {
    let navigate = useNavigate();
    const navItems = [
        {label: 'Home', path: router.home},
        {label: 'About Us', path: router.aboutUs},
        {label: 'Collections', path: router.collections},
        {label: 'Contact', path: router.contact},
    ]
    return (
        <Grid container spacing={2} sx={{bgcolor: 'secondary.main', px: 3, py: 4, color: 'primary.dark'}}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} align="center">
                        <img src={Logo} width="100px"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" sx={{p:3}}>
                            Our commitment doesn't end with a finished package of timber. 
                            We also offer expertise, service, and distribution solutions 
                            that put the finishing touch on every deal.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                            CONTACT US
                        </Typography>
                        <Divider sx={{bgcolor: 'primary.main', width: '10%', borderBottomWidth: 'medium'}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            Bhagowal Road
                        </Typography>
                        <Typography variant="body2">
                            Sialkot, PAKISTAN
                        </Typography>
                        <Typography variant="body2" color="primary" sx={{fontWeight: 'bold'}}>
                            info@the7key.com
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" sx={{display: 'flex'}}>
                            Tel.: <Typography variant="body2" color="primary" sx={{fontWeight: 'bold'}}>
                            +92 300 6417683
                        </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" color="primary" sx={{fontWeight: 'bold'}}>
                            E-mail us
                        </Typography>
                        <Typography variant="body2">
                            info@the7key.com
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                            FOLLOW US
                        </Typography>
                        <Divider sx={{bgcolor: 'primary.main', width: '10%', borderBottomWidth: 'medium'}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <IconButton>
                            <Facebook color="primary"/>
                        </IconButton>
                        <IconButton>
                            <Instagram color="primary"/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                            MENU
                        </Typography>
                        <Divider sx={{bgcolor: 'primary.main', width: '10%', borderBottomWidth: 'medium'}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {navItems.map((item) => (
                            <ListItem key={item.path} disablePadding>
                                <ListItemButton onClick={()=>navigate(item.path)} sx={{p: 0}}>
                                <ListItemText primary={item.label} />
                                </ListItemButton>
                            </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
