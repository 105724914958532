import React from "react";
import {
    Paper, Divider, Grid, Typography
} from '@mui/material';
import logoSpinner from "../../../assets/images/logo/logo_spinner.png";

export default function AboutUsView(props) {

    return (
        <Grid container spacing={3} sx={{py: 5, placeContent: "center", bgcolor: "#6d549638"}}>
            <Grid item xs={12} align="center">
                <Typography variant="h4" sx={{color: 'primary.dark', fontWeight: 'bold', textTransform: "uppercase"}}>
                    About Us
                </Typography>
                <Divider sx={{color: 'primary.main'}}><img src={logoSpinner} width="50px" alt='loading'/></Divider>
            </Grid>
            <Grid item xs={10} sx={{placeContent: "center"}}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{color: 'primary.dark', textTransform: "uppercase"}}>
                            INTRODUCTION
                        </Typography>
                        <Typography variant="body2" sx={{color: 'primary.dark'}}>
                            7key Enterprises [ Driven from Dreams] is a Door to Access Variety of Stuff. From All sorts of [ Hosiery products] to [Leather  products], From [Surgical Beauty Kits] to [Stickers] of different purpose to [ Logo Making & Printing] etc etc
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
