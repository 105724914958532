import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {Paper,CircularProgress,Box,Typography,Button, Grid, Divider} from '@mui/material';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import logoSpinner from "../../../assets/images/logo/logo_spinner.png";
import {router} from "../../../routePaths/routePaths";

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

export default function CollectionsView(props) {
    let navigate = useNavigate();
    useEffect(async()=>{
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    },[])

    const isBottom = (el) => {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }
    
    const onScroll = e => {
        const wrappedElement = document.getElementById('scrollDiv');
        if (isBottom(wrappedElement)) {
            props.bottomReached();
        }
    };
    return (
        <Grid container spacing={3} sx={{py: 5, placeContent: "center", bgcolor: "#6d549638", height: (props.collections.length === 0) ? '100vh' : ''}} id='scrollDiv'>
            {props.collections.map((x,index) => {
                return(
                    <>
                        <Grid item xs={12} align="center">
                            <Divider sx={{mb:3, color: 'primary.main'}}><img src={logoSpinner} width="50px" alt='loading'/></Divider>
                        </Grid>
                        <Grid item xs={12} align="center"
                            sx={{
                                background: `url('${x.col_image}') no-repeat`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                height: '60vh',
                                cursor: 'pointer'
                                }}
                                onClick={()=>navigate(router.collections+"/"+x.c_id)}>
                            <Grid item xs={12} sx={{
                                height: '100%',
                                placeContent: "center",
                                display: 'flex',
                                alignItems: 'end'}}>
                                <Typography variant="h3" sx={{color: 'secondary.main', fontWeight: 'bold', textTransform: "uppercase"}}>
                                    {x.col_name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={10} align="center">
                            <Carousel 
                                autoPlay={false}
                                responsive={responsive}>
                                    {x.products.map((y,z)=>{
                                        return(
                                            <Paper variant="outlined" square onClick={()=>navigate(router.product+"/"+y.p_id)} sx={{mx: 1}}>
                                                <Paper 
                                                    elevation={0}
                                                    sx={{cursor: 'pointer', height: '250px', overflow: 'hidden', p: 0}} 
                                                    square>
                                                    <img src={JSON.parse(y.pro_image)[0]} width='100%' alt={index}/>
                                                </Paper>
                                                <Grid container spacing={2} sx={{py:2}}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1" sx={{color: 'primary.main'}}>
                                                            {y.pro_name}
                                                        </Typography>
                                                        <Divider sx={{bgcolor: 'secondary.main', width: '10%', borderBottomWidth: 'medium'}}/>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button 
                                                            className="btn-yellow" 
                                                            size="small"
                                                            sx={{px:2}}
                                                            onClick={()=>navigate(router.product+'/'+y.p_id)}>
                                                            see detail
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Paper>

                                        )
                                    })}
                            </Carousel>
                            <Grid item xs={12} align="center">
                                <Button type='button' className='btn-blue' sx={{width: '150px', mt: 2}} onClick={()=>navigate(router.collections+"/"+x.c_id)}>
                                    View ALL
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )
            })}
            <Grid item xs={12} align="center">
                {props.isLoading &&
                    <Box position="relative" display="inline-flex">
                     <CircularProgress sx={{color: '#734d03'}}/>
                     <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography variant="caption" component="div" color="textSecondary">
                            <img src={logoSpinner} width= '50px' alt='loading'/>
                        </Typography>
                    </Box>
                </Box>}
            </Grid>   
        </Grid>
    );
}
