import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidenav from '../pages/Admin/Sidenav/Sidenav';

const MenuBar = () => (
  <Grid >
    <Grid>
      <Sidenav />
    </Grid>    
    <Grid item sx={{px: 3, py: '100px', bgcolor: "#6d549638"}}>
      <Outlet />
    </Grid>
  </Grid>
);
export default MenuBar;