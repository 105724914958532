import React, {useState, useEffect} from "react";
import Footer from "../Common/Footer/Footer";
import Header from "../Common/Header/Header";
import HomeView from "./HomeView";
import Spinner from "../../../shared/Spinner";
import productsService from "../../../services/products-service";


export default function Home() {
    const [spinner, setSpinner] = useState(false);
    const [homeRecord, setHomeRecord] = useState([]);

    const getHomeRecord=async() => {
        setSpinner(true);
        const res = await productsService.getHomeRecord();
        setHomeRecord(res.data);
        setSpinner(false);
    }

    useEffect(()=>{
        getHomeRecord();
    },[])

    return (
        <>
            <HomeView 
                homeRecord = {homeRecord}
            />
            <Spinner
                spinner = {spinner}
            />
        </>
    );
}
