import React from "react";
import AboutUsView from "./AboutUsView";

export default function AboutUs() {

    return (
        <>
            <AboutUsView
            />
        </>
    );
}
