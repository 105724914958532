import React from "react";
import { styled } from '@mui/material/styles';
import { 
    Button, Typography, Table, TableBody, TableContainer,
    TableHead, TableRow, Paper, TablePagination, Switch, Grid
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { DeleteForeverOutlined, EditOutlined } from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

export default function CollectionsView(prop) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    
    return (
        <Grid container spacing={3} sx={{placeContent: "center", height: '92vh', bgcolor: '#dfd9e8'}}>
            <Grid item xs={6} align="start">
                <Typography variant="subtitle1" color="primary">
                    COLLECTIONS
                </Typography>
            </Grid>
            <Grid item xs={6} align="end">
                <Button 
                    type="button"
                    className='btn-yellow'
                    sx={{minWidth:110}}
                    onClick={prop.handleOpen}
                >
                        Add +
                </Button>
            </Grid>
            <Grid item xs={12} align="center">
                <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden', bgcolor: '#f0eff3' }}>
                    <TableContainer sx={{ height: '65vh' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell align="right">Image</StyledTableCell>
                                    {/* <StyledTableCell align="right">Featured</StyledTableCell> */}
                                    <StyledTableCell align="right">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {prop.collections
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">
                                                {row.col_name}
                                            </TableCell>
                                            <TableCell align="right">
                                                <img src={row.col_image} width="50px" alt="loading"/>
                                            </TableCell>
                                            {/* <TableCell align="right">
                                                <Switch
                                                    checked={row.col_featured === "1"}
                                                    onChange={(e)=> prop.changeFeature(e,row.id)}
                                                    color="primary"
                                                    name="col_featured"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell> */}
                                            <TableCell  align="right">
                                                <Button 
                                                    type="buttnon" 
                                                    onClick={()=>prop.delItem(row.id)} 
                                                    sx={{color: '#bc0000', background: '#bc00002e', p: '2px', minWidth: 0, m: 1}}
                                                >
                                                    <DeleteForeverOutlined />
                                                </Button>
                                                <Button
                                                    type="buttnon" 
                                                    onClick={()=>prop.editItem(row)}
                                                    sx={{color: '#027c92', background: '#027c924d', p: '2px', minWidth: 0, m: 1}}
                                                >
                                                    <EditOutlined/>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
                        component="div"
                        count={prop.collections.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        SelectProps={{
                            MenuProps: { classes: "selectDropdown" }
                        }}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}
