import AxiosServices from './axios-service';
import {endpoints} from './api-end-points';

export class SublimationsService {

  addSublimation(body){    
    const res = AxiosServices.post(endpoints.sublimations.addSublimation, body);
    return res;
  }

  editSublimation(body){    
    const res = AxiosServices.post(endpoints.sublimations.editSublimation, body);
    return res;
  }

  getSublimations(body){
    const res = AxiosServices.post(endpoints.sublimations.getSublimations,body);
    return res;
  }

}

const sublimationsService = new SublimationsService();
export default sublimationsService;
