import { config } from '../Constants';
export const endpoints = {

  ///---Common service--///

  common : {
    get_record : config.url.API_URL + 'get_record',
    save_record : config.url.API_URL + 'save_record',
    edit_record : config.url.API_URL + 'edit_record',
    del_record : config.url.API_URL + 'del_record',
    get_record_by_id : config.url.API_URL + 'get_record_by_id',
    sendMail : config.url.API_URL + 'sendMail',
  },
  
  ///---Login service--///

  auth : {
      signup : config.url.API_URL + 'signup',
      login : config.url.API_URL + 'login',
      resetPassword : config.url.API_URL + 'resetPassword',
      verify : config.url.API_URL + 'verify',
      adminLogin : config.url.API_URL + 'adminLogin',
      editPassword : config.url.API_URL + 'editPassword',
  },

  ///---Home service--///

  home : {
      getHomeRecord : config.url.API_URL + 'getHomeRecord',
      getProductByCat : config.url.API_URL + 'getProductByCat',
      getCollectionsByCat : config.url.API_URL + 'getCollectionsByCat',
  },

  ///---Dashbboard service--///

  dashboard : {
    getDashboard : config.url.API_URL + 'getDashboard',
    addDashboard : config.url.API_URL + 'addDashboard',
    editDashboard : config.url.API_URL + 'editDashboard',
  },

  ///---Category service--///

  categories : {
    getCategories : config.url.API_URL + 'getCategories',
    addCategory : config.url.API_URL + 'addCategory',
    editCategory : config.url.API_URL + 'editCategory',
  },

  ///---Collections service--///

  collections : {
    getCollections : config.url.API_URL + 'getCollections',
    addCollection : config.url.API_URL + 'addCollection',
    editCollection : config.url.API_URL + 'editCollection',
    editFeatured : config.url.API_URL + 'editFeatured',
    getCollectionPagination : config.url.API_URL + 'getCollectionPagination',
    getCollectionsByCol : config.url.API_URL + 'getCollectionsByCol',
  },

  ///---Collections service--///

  brands : {
    getBrands : config.url.API_URL + 'getBrands',
    addBrand : config.url.API_URL + 'addBrand',
    editBrand : config.url.API_URL + 'editBrand',
  },

  ///---Products service--///

  products : {
    getProducts : config.url.API_URL + 'getProducts',
    addProduct : config.url.API_URL + 'addProduct',
    editProduct : config.url.API_URL + 'editProduct',
    editProductFeatured : config.url.API_URL + 'editProductFeatured', 
    getProductDetail : config.url.API_URL + 'getProductDetail', 
    getProductById : config.url.API_URL + 'getProductById', 
    getProductsByCol : config.url.API_URL + 'getProductsByCol', 
  },


  sublimations: {
    addSublimation : config.url.API_URL + 'addSublimation', 
    editSublimation : config.url.API_URL + 'editSublimation', 
    getSublimations : config.url.API_URL + 'getSublimations', 
  },

  subscriptions: {
    userSubscribe : config.url.API_URL + 'userSubscribe', 
  },

  search: {
    searchBrandProducts : config.url.API_URL + 'searchBrandProducts', 
  },

  order: {
    checkout : config.url.API_URL + 'checkout', 
    get_new_orders : config.url.API_URL + 'get_new_orders', 
    update_noti_status : config.url.API_URL + 'update_noti_status', 
  }

}