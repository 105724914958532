import React, {useState, useEffect, useRef} from "react";
import { useParams } from "react-router-dom";
import sublimationsService from "../../../services/sublimations-service";
import SublimationsListView from "./SublimationsListView";
import Spinner from "../../../shared/Spinner";

export default function SublimationsList() {
    const [spinner, setSpinner] = useState(false);
    const [sublimations, setProducts] = useState([]);
    const [filterOptions, setFilterOptions] = useState([]);
    const [filtersTable, setFiltersTable] = useState({});
    const [sorted, setSorted] = useState({
        sortBy : "id", order: 'desc'
    });
    const [searched, setSearched] = useState("");
    const [rows, setRows] = useState([]);
    let start = useRef(0);
    let totalRecord = 0;
    let isLoading = false;

    useEffect(async ()=>{
        setSpinner(true);
        getSublimations(start.current,false,sorted);
    },[])

    const getSublimations=async(body,filter,order) =>{
        start.current = (!filter) ? body: 0;
        isLoading = true;
        const res = await sublimationsService.getSublimations({
            start: body, filter: filterOptions, sortBy: order.sortBy, order: order.order
        });
        if(!filter){
            setProducts((sublimations) => ([...sublimations,...res.data]));
            setRows((sublimations) => ([...sublimations,...res.data]));
        }else{
            setProducts(res.data);
            setRows(res.data);
        }
        isLoading = false;
        setSpinner(false);
    }

    const handleFilter=async(key,value,name)=>{
        setSpinner(true);
        let options = filterOptions;
        options.map((x,y)=>{
            let findKey = Object.keys(x);
            if(findKey[0] === key){
                options.splice(y, 1)
            }
        })
        options.push({[key]: value, name: name})
        setFilterOptions([...options]);
        getSublimations(0,true,sorted);
    }

    const handleDelete=async(item)=>{
        setSpinner(true);
        let options = filterOptions;
        options.splice(options.findIndex(function(i){
            return i.name === item.name;
        }), 1);
        setFilterOptions([...options]);
        getSublimations(0,true,sorted);
    }

    const handleSorted = (el) =>{
        console.log(el);
        let obj = {}
        if(el === 0){
            obj={
                sortBy : "id", order: 'desc'
            }
        }else if(el === 1){
            obj={
                sortBy : "id", order: 'asc'
            }
        }
        setSorted(obj);
        getSublimations(0,true,obj);
    }

    const clearFilter = async () =>{
        setFilterOptions([...[]]);
        setSpinner(true);
        getSublimations(start.current,false,sorted);
    }

    const requestSearch = (searchedVal) => {
        const filteredRows = rows.filter((row) => {
          return row.sub_name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setSearched(searchedVal);
        setProducts(filteredRows);
    };

    const bottomReached = () => {
        let body = parseInt(start.current)+8
        if(!isLoading && totalRecord > body){
            getSublimations(body,false,sorted);
        }
    };

    return (
        <>
            <SublimationsListView
                sublimations = {sublimations}
                filtersTable = {filtersTable}
                filterOptions = {filterOptions}
                isLoading = {isLoading}
                searched = {searched}

                handleFilter = {(key,value,name)=>handleFilter(key,value,name)}
                clearFilter = {()=>clearFilter()}
                handleDelete = {(item)=>handleDelete(item)}
                handleSorted = {(item)=>handleSorted(item)}
                requestSearch = {(e)=>requestSearch(e)}
                bottomReached = {()=>bottomReached()}
            />
            <Spinner
                spinner = {spinner}
            />
        </>
    );
}
