import React, {useState, useEffect} from "react";
import authServices from "../../../services/auth-service";
import commonService from "../../../services/common-service";
import ConfirmDiallog from "../../../shared/ConfirmDiallog";
import SnackAlert from "../../../shared/SnackAlert";
import Spinner from "../../../shared/Spinner";
import ProfileView from "./ProfileView";

export default function AdminProfile() {
    const isLogged = JSON.parse(localStorage.getItem('isLogged'));
    
    const [spinner, setSpinner] = useState(false);
    const [alert, setAlert] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [passwordNotMatch, setPasswordNotMatch] = useState(false);
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState('');
    const [severity, setSeverity] = useState('');
    const [inputField, setInputField] = useState({});
    const [inputPassword, setInputPassword] = useState({});

    useEffect(()=>{
        setInputField(JSON.parse(localStorage.getItem('isLogged')));
    },[])
    
    const handleChange=(e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handlePasswordChange=(e) => {
        setInputPassword({...inputPassword, [e.target.name]: e.target.value});
        if(inputPassword['password'] === e.target.value){
            setPasswordNotMatch(false);
        }
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        inputField['id'] = isLogged.id;
        inputField['table_name'] = 'users';
        delete inputField.password;
        const res = await commonService.edit_record(inputField);
        localStorage.setItem("isLogged", JSON.stringify(res.data[0]));
        setMsg('Profile updated successfuly')
        setSeverity('success')
        setAlert(true)
        setSpinner(false);
    }

    const updateSubmit=async(e) => {
        e.preventDefault();
        inputPassword['id'] = isLogged.id;
        if(inputPassword['password'] !== inputPassword['confirm_password']){
            setPasswordNotMatch(true);
            return;
        }
        setSpinner(true);
        await authServices.editPassword(inputPassword);
        setPasswordNotMatch(false);
        inputField['password'] = inputPassword['password'];
        setOpen(false);
        setMsg('Password updated successfuly')
        setSeverity('success')
        setAlert(true)
        setSpinner(false);
    }

    return (
        <div>
            <ProfileView 
                inputField = {inputField}
                inputPassword = {inputPassword}
                passwordNotMatch = {passwordNotMatch}
                open = {open}

                handleSubmit = {(e) => handleSubmit(e)}
                handleChange = {(e) => handleChange(e)}
                updateSubmit = {(e) => updateSubmit(e)}
                handlePasswordChange = {(e) => handlePasswordChange(e)}
                setOpen = {() => setOpen(true)}
                setClose = {() => setOpen(false)}
            />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
            <ConfirmDiallog
                openDialog = {openDialog}

                closeDialog = {()=>setOpenDialog(false)}
            />
        </div>
    );
}
