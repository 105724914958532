import * as React from 'react';
import PropTypes from 'prop-types';
import { 
    Grid, AppBar, Box, Divider, Drawer, IconButton,
    List, ListItem, ListItemButton, ListItemText,
    Toolbar, Button
} from '@mui/material';
import { Menu as MenuIcon, Close } from '@mui/icons-material';
import { router } from '../../../../routePaths/routePaths';
import Logo from "../../../../assets/images/logo/logo.png";
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;
const navItems = [
    {label: 'Home', path: router.home},
    {label: 'About Us', path: router.aboutUs},
    {label: 'Collections', path: router.collections},
    {label: 'Sublimations', path: router.sublimations},
    {label: 'Contact', path: router.contact},
]

function HeaderView(props) {
    let navigate = useNavigate();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ my: 2 }}
            >
                <Close />
            </IconButton>
            <Divider />
            <List>
                {navItems.map((item) => (
                <ListItem key={item.path} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={()=>navigate(item.path)}>
                    <ListItemText primary={item.label} />
                    </ListItemButton>
                </ListItem>
                ))}
            </List>
        </Box>
    );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
        <AppBar component="nav">
            <Toolbar variant="dense">
                <Grid container spacing={2} sx={{ display: { sm: 'none' } }} alignItems="center">
                    <Grid item xs={6}>
                        <IconButton
                            onClick={()=>navigate(router.home)}
                        >
                            <img src={Logo} width="25px"/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Box sx={{ display: { xs: 'none', sm: 'block' }, width:'100%', textAlign: 'center' }}>
                    {navItems.map((item) => (
                    <Button key={item.path} color="tertairy" onClick={()=>navigate(item.path)}>
                        {item.label}
                    </Button>
                    ))}
                </Box>
            </Toolbar>
        </AppBar>
        <Box component="nav">
            <Drawer
                anchor="right"
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                PaperProps={{
                    sx: {
                      backgroundColor: "primary.main",
                      color: 'tertairy.main'
                    },
                  }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    </Box>
  );
}

HeaderView.propTypes = {window: PropTypes.func};

export default HeaderView;
