import React, {useState, useEffect} from "react";
import dashboardService from "../../../services/dashboard-service";
import Spinner from "../../../shared/Spinner";
import DashboardView from "./DashboardView";

export default function Dashboard() {
    const [spinner, setSpinner] = React.useState(false);
    const [dashboard, setDashboard] = useState({
        collections: [],
        brands: [],
        products: [],
        collectionsLength: 0,
        brandsLength: 0,
        productsLength: 0,
    });

    useEffect(()=>{
        getDashboard();
    },[])

    const getDashboard=async()=>{
        setSpinner(true);
        const res = await dashboardService.getDashboard();
        setDashboard(res.data);
        setSpinner(false);
    }

    return (
        <>
            <DashboardView 
                dashboard = {dashboard}
            />
            <Spinner
                spinner = {spinner}
            />
        </>
    );
}
