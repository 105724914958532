import React, { useState, useEffect } from "react";
import productsService from "../../../../services/products-service";
import HeaderView from "./HeaderView";
import Spinner from "../../../../shared/Spinner";
import searchService from "../../../../services/search-service";

export default function Header() {
    const [leftDrawer, setLeftDrawer] = useState(false);
    const [openSearchModal, setOpenSearchModal] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [searchRecord, setSearchRecord] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    
    const getRecord=async() => {
        setSpinner(true);
        const res = await productsService.getHomeRecord();
        setSearchRecord(res.data);
        setFilteredData(res.data);
        setSpinner(false);
    }

    const onOpenDrawer = () => {
        setOpenSearchModal(false)
        setLeftDrawer(true)
    }

    const handleChange = async(e) => {
        if(e.target.value === ""){
            setFilteredData(searchRecord);
            return;
        }
        const res = await searchService.searchBrandProducts({query: e.target.value});
        let products = [];
        products['products'] = res.data;
        setFilteredData(products);
    }

    useEffect(()=>{
        getRecord();
    },[])
    
    return (
        <div>
            <HeaderView
                openSearchModal={openSearchModal}
                homeRecord = {filteredData}
                
                onOpenDrawer={(cat)=>onOpenDrawer()}
                setOpenSearchModal={()=>setOpenSearchModal(true)}
                setCloseSearchModal={()=>setOpenSearchModal(false)}
                handleChange={(e)=>handleChange(e)}
            />
            <Spinner
                spinner = {spinner}
            />
        </div>
    );
}
