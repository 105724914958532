import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import {
    Box, TextField, Typography, Button, Grid, 
    Divider, InputAdornment, IconButton, useMediaQuery
} from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import authServices from "../../../services/auth-service";
import {router} from "../../../routePaths/routePaths";
import SnackAlert from "../../../shared/SnackAlert";
import Spinner from "../../../shared/Spinner";
  
export default function Login() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [spinner, setSpinner] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [alert, setAlert] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [inputField, setInputField] = useState({email:'',password:''});
    
    const handleChange = (e) => {
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'), {
        defaultMatches: true,
        noSsr: false
        });

        
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',
    width: smDown ? '70%' : "40%",
    boxShadow: 24,
    p: 4,
    bgcolor: "#6d549638"
  };

    useEffect(()=>{
    },[])

    const submitForm = async (e) => {
        e.preventDefault();
        setSpinner(true);
        const res = await authServices.adminLogin(inputField);
        console.log(res.data);
        if(res.data.error){
            setMsg(res.data.message);
            setAlert(true);
            setSeverity('error');
        }else{
            localStorage.setItem("isLogged", JSON.stringify(res.data[0]));
            navigate(router.dashboard);
        }
        setSpinner(false);
    }

    return (
        <Grid container spacing={3} sx={{py: 5, placeContent: "center"}}>
            <Grid item xs={12} align="center">
                <Box sx={style}>
                    <Typography variant="h6" sx={{color: 'primary.dark', fontWeight: 'bold', textTransform: "uppercase"}}>
                        Login
                    </Typography>
                    <Divider/>
                    <form onSubmit={submitForm}>
                        <Grid container spacing={3} sx={{py: 5, placeContent: "center"}}>
                            <Grid item xs={12} align="center">
                                <TextField
                                    id="standard-basic" 
                                    label="Email"
                                    name='email' value={inputField.email}
                                    type='email'
                                    size = "small"
                                    variant="outlined" 
                                    sx={{width:'90%'}} onChange={handleChange}
                                    required/>
                            </Grid>
                            <Grid item xs={12} align="center">
                                <TextField
                                    id="standard-basic" 
                                    label="Password"
                                    type={(showPassword) ? 'text':'password'}
                                    name='password' value={inputField.password}
                                    size = "small"
                                    variant="outlined" 
                                    sx={{width:'90%'}} onChange={handleChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                            onClick={()=>setShowPassword(!showPassword)}
                                            edge="end"
                                            >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                        }}
                                    required/>
                            </Grid>
                            <Grid item xs={12} align="end">
                                <Button type='submit' className='btn-blue' sx={{px:2 }}>Submit</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Grid>
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </Grid>
    );
}
