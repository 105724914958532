import * as React from 'react';
import {
  Backdrop, Box, Modal, Fade, TextField, 
  Typography, Button, Divider,Grid
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

export default function AddCollection(prop) {

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={prop.open}
        onClose={prop.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={prop.open}>
          <Box sx={style} className='width-50'>
            <form onSubmit={prop.handleSubmit}>
              <Grid container spacing={3} sx={{placeContent: "center"}}>
                <Grid item xs={12} align="center">
                  <Typography variant="subtitle1" color="primary">{prop.btn}</Typography>
                  <Divider/>
                </Grid>
                <Grid item xs={12} align="center">
                  <TextField
                    id="standard-basic" 
                    label="Name" variant="outlined" 
                    name="col_name" value={prop.inputField['col_name']} 
                    sx={{width: '90%'}} size="small" onChange={prop.handleChange}
                    required/>
                </Grid>
                <Grid item xs={12} align="center">
                  <Grid sx={{display: 'inline-flex', width: '90%',
                      color: '#666666', fontFamily: 'sans-serif',
                      border: '1px solid #cdc9c9', 
                      alignItems: 'center', padding: '8px', 
                      borderRadius: '5px'}}>
                    <label class="file-label" for="input-file" className='mt-4'>Image:&nbsp;</label>
                    <input type="file" name="col_image"
                        className='mt-3'
                        onChange={(e)=>prop.setImage(e.target.files[0])}
                        accept="image/png, image/jpg, image/jpeg"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} align="center">
                  <Button type='submit' className='btn-blue' size="small" sx={{mx: 2}}>
                    {prop.btn}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
