import React from "react";
import { useNavigate  } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {
    Paper, Table, TableBody, Grid,
    TableCell, TableHead, TableRow, Button,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import {
    Inventory2Outlined,
    Category
  } from '@mui/icons-material';
import SmallCard from "../../../components/SmallCard";
import {router} from "../../../routePaths/routePaths";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

export default function DashboardView(prop) {
    const navigate = useNavigate ();

    return (
        <Grid container spacing={3} sx={{placeContent: "center"}}>
            <Grid item xs={12} sm={12} lg={6} md={6} align="center">
                <SmallCard
                    icon = {<Category sx={{fontSize: '60px', color: 'primary.main'}}/>}
                    heading = {prop.dashboard.collectionsLength}
                    caption = {'Collections'}
                    buttonText = {'Add New'}
                    router = {router.adminCollections}
                /> 
            </Grid>
            <Grid item xs={12} sm={12} lg={6} md={6} align="center">
                <SmallCard
                    icon = {<Inventory2Outlined sx={{fontSize: '60px', color: 'primary.main'}}/>}
                    heading = {prop.dashboard.productsLength}
                    caption = {'Products'}
                    buttonText = {'Add New'}
                    router = {router.adminProducts}
                /> 
            </Grid>
            <Grid item xs={12} sm={12} lg={6} md={6} align="center" sx={{mb:4}}>
                <Paper elevation={3} sx={{ display: 'flex', p: 1, my:1, overflow: 'auto', height: '97%'}}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={5} sx={{color:'primary.main'}}>
                                    COLLECTIONS
                                </TableCell>
                            </TableRow>
                            <TableRow className="bg-grey">
                                <StyledTableCell>NAME</StyledTableCell>
                                <StyledTableCell align="right">IMAGE</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {prop.dashboard['collections'].map((x)=>{
                                return(
                                <TableRow>
                                    <TableCell>{x.col_name}</TableCell>
                                    <TableCell align="right">
                                        <img src={x.col_image} width='60px' alt="loading"/>
                                    </TableCell>
                                </TableRow>
                                )
                            })}
                            <TableRow>
                                <TableCell colSpan={5} sx={{border:0}}>
                                    <Button color="secondary" variant="outlined" size="small" onClick={()=>navigate(router.adminCollections)} sx={{verticalAlign: 'bottom'}}>
                                        View All
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} md={6} align="center" sx={{mb:4}}>
                <Paper elevation={3} sx={{ display: 'flex', p: 1, my:1, overflow: 'auto', height: '97%'}}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={5} sx={{color:'primary.main'}}>
                                    PRODUCTS
                                </TableCell>
                            </TableRow>
                            <TableRow className="bg-grey">
                                <StyledTableCell>NAME</StyledTableCell>
                                <StyledTableCell align="right">IMAGE</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {prop.dashboard['products'].map((x)=>{
                                let images = JSON.parse(x.pro_image);
                                return(
                                <TableRow>
                                    <TableCell>{x.pro_name}</TableCell>
                                    <TableCell align="right">
                                        {images.map(y => {
                                            return(
                                                <img src={y} width='60px' style={{marginLeft: '5px'}} alt="loading"/> 
                                            )
                                        })}
                                    </TableCell>
                                </TableRow>
                                )
                            })}
                            <TableRow>
                                <TableCell colSpan={5} sx={{border:0}}>
                                    <Button color="secondary" variant="outlined" size="small" onClick={()=>navigate(router.adminProducts)} sx={{verticalAlign: 'bottom'}}>
                                        View All
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        </Grid>
    );
}
