import React, {useState} from "react";
import { 
    Button, TextField, InputAdornment, IconButton, Backdrop,
    Modal, Fade, Box, Typography, Divider, Grid
} from "@mui/material";
import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
  };

export default function ProfileView(prop) {
    const [showPassword, setShowPassword] = useState(false);
    
    return (
        <Grid container spacing={3} sx={{pt: 5, pb: '90px', placeContent: "center", height: '92vh', bgcolor: '#dfd9e8'}}>
            <Grid item xs={12} align="center">
                <form onSubmit={prop.handleSubmit}>
                    <Grid container spacing={3} sx={{py: 5, placeContent: "center"}}>
                        <Grid item xs={12} sm={12} lg={6} md={6} align="center">
                            <TextField
                                id="standard-basic" 
                                label="First Name" variant="outlined" 
                                name="fname" value={prop.inputField['fname']} 
                                sx={{width: '90%'}} size="small" onChange={prop.handleChange}
                                required/>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6} align="center">
                            <TextField
                                id="standard-basic" 
                                label="Last Name" variant="outlined" 
                                name="lname" value={prop.inputField['lname']} 
                                sx={{width: '90%'}} size="small" onChange={prop.handleChange}
                                required/>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6} align="center">
                            <TextField
                                id="standard-basic" 
                                label="Email" variant="outlined" 
                                name="email" value={prop.inputField['email']} 
                                sx={{width: '90%'}} size="small" onChange={prop.handleChange}
                                required/>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6} align="center">
                            <TextField
                                id="standard-basic" 
                                label="Address" variant="outlined" 
                                name="address" value={prop.inputField['address']} 
                                sx={{width: '90%'}} size="small" onChange={prop.handleChange}
                                required/>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6} align="center">
                            <TextField
                                id="standard-basic" 
                                label="Phone no" variant="outlined" 
                                type={'number'}
                                name="ph_no" value={prop.inputField['ph_no']} 
                                sx={{width: '90%'}} size="small" onChange={prop.handleChange}
                                required/>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} md={6} align="center">
                            <TextField
                                id="standard-basic" 
                                label="Password" variant="outlined" 
                                type={'password'}
                                name="password" value={prop.inputField['password']} 
                                sx={{width: '90%'}} size="small" onChange={prop.handleChange}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                            onClick={()=>prop.setOpen()}
                                            edge="end"
                                        >
                                            <Edit />
                                        </IconButton>
                                    </InputAdornment>,
                                    readOnly: true
                                    }}
                                required/>
                        </Grid>
                        <Grid item xs={12} align="center">
                            <Button type='submit' className="btn-blue" size="small">Update Profile</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={prop.open}
                onClose={()=>prop.setClose()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={prop.open}>
                <Box sx={style} className='width-50'>
                    <Typography className='font-bold'>Update Password</Typography>
                    <Divider className='my-2'/>
                        <form onSubmit={prop.updateSubmit}>
                            <Grid container spacing={3} sx={{py: 5, placeContent: "center"}}>
                                <Grid item xs={12} sm={12} lg={6} md={6} align="center">
                                    <TextField
                                        id="standard-basic" 
                                        label="Password" variant="outlined" 
                                        type={(showPassword) ? 'text':'password'}
                                        name="password" value={prop.inputPassword['password']} 
                                        sx={{width: '90%'}} size="small" onChange={prop.handlePasswordChange}
                                        required
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                onClick={()=>setShowPassword(!showPassword)}
                                                edge="end"
                                                >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                            }}/>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={6} md={6} align="center">
                                    <TextField
                                        id="standard-basic" 
                                        label="Confirm Password" variant="outlined" 
                                        type={(showPassword) ? 'text':'password'}
                                        name="confirm_password" value={prop.inputPassword['confirm_password']} 
                                        sx={{width: '90%'}} size="small" onChange={prop.handlePasswordChange}
                                        error={prop.passwordNotMatch}
                                        helperText={prop.passwordNotMatch ? 'Passord do not match' : ' '}
                                        required/>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Button type='submit' className='btn-blue' sx={{px:2}} size="small">Update</Button>
                                </Grid>
                            </Grid>
                            <div className='row'>
                                <div className='col-lg-6 text-center'>
                                    
                                </div>
                                <div className='col-lg-6 text-center'>
                                    
                                </div>
                            </div>
                            <div className='row justify-content-center'>
                            </div>
                        </form>
                </Box>
                </Fade>
            </Modal>
        </Grid>
    );
}
