import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Footer from '../pages/User/Common/Footer/Footer';
import Header from '../pages/User/Common/Header/Header';

const Wrapper = () => (
  <Grid >
    <Grid>
      <Header />
    </Grid>
    <Grid item sx={{marginTop: '48px'}}>
      <Outlet />
    </Grid>
    <Grid>
      <Footer />
    </Grid>
  </Grid>
);
export default Wrapper;