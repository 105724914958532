import React from "react";
import { useNavigate  } from "react-router-dom";
import {
    Paper, CardContent, Typography, Button, Grid
} from '@mui/material';


export default function SmallCard(prop) {
const navigate = useNavigate ();
return (
        <Paper elevation={3} sx={{ display: 'flex', p: 1, my:1}}>
            <Grid container spacing={3} sx={{py: 1, placeContent: "center"}}>
                <Grid item xs={4} align="center">
                    {prop.icon}                    
                </Grid>
                <Grid item xs={8} align="center">
                    <CardContent sx={{p: 0}}>
                        <Typography component="div" variant="h5" color={'primary'}>
                            {prop.heading}
                        </Typography>
                        <Typography variant="caption" color="secondary" component="div">
                            {prop.caption}
                        </Typography>
                    </CardContent>
                    <Button color="primary" variant="outlined" size="small" onClick={()=>navigate(prop.router)}>
                        {prop.buttonText}
                    </Button>
                </Grid>
            </Grid>
        </Paper> 
    );
}
