import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import SublimationDetailView from "./SublimationDetailView";
import Spinner from "../../../shared/Spinner";
import SnackAlert from "../../../shared/SnackAlert";
import commonService from "../../../services/common-service";
import sublimationsService from "../../../services/sublimations-service";

export default function SublimationDetail() {
    const {id} = useParams()
    const [spinner, setSpinner] = useState(false);
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [sublimationDetail, setSublimationDetail] = useState({});
    const [sublimations, setSublimations] = useState([]);
    const [image, setImage] = useState("");

    useEffect(async()=>{
        getSublimation();
        getSublimationDetail();
    },[id])

    const getSublimationDetail=async() =>{
        setSpinner(true);
        const res = await commonService.get_record_by_id({id: id, table_name: 'sublimations'})
        let response = res.data[0];
        response.sub_image = JSON.parse(response.sub_image)
        console.log(response)
        setSublimationDetail(response)
        setImage(response?.sub_image[0])
        setSpinner(false);
    }

    const getSublimation=async() =>{
        setSpinner(true);
        const res = await sublimationsService.getSublimations({
            start: 0, filter: [], sortBy: 'id', order: 'desc'
        });
        setSublimations(res.data);
        setSpinner(false);
    }

    return (
        <>
            <SublimationDetailView
                sublimationDetail = {sublimationDetail}
                image = {image}
                sublimations = {sublimations}

                onImageSelect = {(e)=>setImage(e)}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
            <Spinner
                spinner = {spinner}
            />
        </>
    );
}
