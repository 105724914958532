const prod = {
    url: {
        API_URL: 'https://the7key.com/sevenkeys-api/index.php/' ,
        Publishable_key: '',
    }
};
const dev = {
    url: {
        // API_URL: 'https://kulturking.com/kulturking-api/index.php/' ,
        API_URL: 'http://localhost/sevenkeys-api/index.php/' ,
    }
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;