import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {
    Paper, Chip, InputLabel, MenuItem, FormControl, 
    Select, Button, IconButton, Grid, Box,
    InputBase,  Divider, Stack, CircularProgress,Typography
} from '@mui/material';
import {FilterList } from '@mui/icons-material';
import logoSpinner from "../../../assets/images/logo/logo_spinner.png"
import {router} from "../../../routePaths/routePaths";

const modelStyle = {
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -0%)',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
    height:'100%'
  };

export default function SublimationsListView(props) {
    let navigate = useNavigate();
    const [sortBy, setSortBy] = useState(0);
    const [openFilterModal, setOpenFilterModal] = useState(false);

    const handleChange = (e) => {
        setSortBy(e.target.value);
        props.handleSorted(e.target.value)
    };

    useEffect(async()=>{
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    },[])

    const isBottom = (el) => {
        return el.getBoundingClientRect().bottom <= window.innerHeight;
      }
    
    const onScroll = e => {
        const wrappedElement = document.getElementById('scrollDiv');
        if (isBottom(wrappedElement)) {
            props.bottomReached();
        }
    };

    return (
        <Grid container spacing={3} sx={{py: 5, placeContent: "center", bgcolor: "#6d549638", height: (props.sublimations.length === 0) ? '100vh' : ''}}>
            <Grid item xs={12} align="center">
                <Typography variant="h4" sx={{color: 'primary.main', fontWeight: 'bold', textTransform: "uppercase"}}>
                    SUBLIMATIONS
                </Typography>
            </Grid>
            <Grid item xs={12} align="center">
                <Divider sx={{mb:3, color: 'primary.main'}}><img src={logoSpinner} width="50px" alt='loading'/></Divider>
            </Grid>
            <Grid item xs={12} align="center" id='scrollDiv'>
                <Grid item xs={10} align="center">
                    <Grid container spacing={3} sx={{placeContent: "center", alignItem: 'center'}}>
                        <Grid item xs={12} sm={12} md={4} lg={4} align="center">
                            <InputBase
                                sx={{ background: '#fff', px: 2, py: 1, width: '100%' }}
                                placeholder="Search Sublimation"
                                value={props.searched}
                                onChange={(e) => props.requestSearch(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} align="center">
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} align="end">
                            <FormControl sx={{width:'100%'}} size="small">
                                <InputLabel id="demo-simple-select-label">Sort By:</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={sortBy}
                                    label="Sort By:"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={0}>New to Old</MenuItem>
                                    <MenuItem value={1}>Old to New</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={10} align="center">
                    {props.sublimations.map((x,index)=>{
                        return(
                            <>
                                <Paper sx={{my:6, background: '#d4c9e4'}}>
                                    <Grid container sx={{placeContent: "center"}}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} align="center">
                                            <Grid item xs={10} sx={{cursor: 'pointer'}} onClick={()=>navigate(router.sublimations+"/"+x.id)}>
                                                <img 
                                                    src={JSON.parse(x.sub_image)[0]} 
                                                    onMouseOver={e => (e.currentTarget.src = (JSON.parse(x.sub_image)[0] !== undefined) ? JSON.parse(x.sub_image)[1] : JSON.parse(x.sub_image)[0])}
                                                    onMouseOut={e => (e.currentTarget.src = JSON.parse(x.sub_image)[0])} 
                                                    width='100%' 
                                                    // height={'350px'}
                                                    alt='loading'
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} align="center" alignSelf="center">
                                            <Grid item xs={10} sx={{cursor: 'pointer', height: '100%'}} onClick={()=>navigate(router.sublimations+"/"+x.id)}>
                                                <Grid container spacing={2} sx={{py:2}}>
                                                    <Grid item xs={12} align="start">
                                                        <Typography variant="h5" sx={{fontWeight: 'bold', color: 'primary.dark'}}>
                                                            {x.sub_name}
                                                        </Typography>
                                                        <Divider sx={{bgcolor: 'secondary.main', width: '20%', borderBottomWidth: 'medium'}}/>
                                                    </Grid>
                                                    <Grid item xs={12} align={"left"}>
                                                        <Typography variant="body2" sx={{color: 'primary.dark', whiteSpace: 'pre-line'}}>
                                                            {x.sub_description}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid> 
                                    </Grid>
                                </Paper>
                            </>
                            )
                        })}
                </Grid>
            </Grid>
            <Grid item xs={12} align="center">
                {props.isLoading &&
                    <Box position="relative" display="inline-flex">
                     <CircularProgress sx={{color: '#734d03'}}/>
                     <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography variant="caption" component="div" color="textSecondary">
                            <img src={logoSpinner} width= '50px' alt='loading'/>
                        </Typography>
                    </Box>
                </Box>}
            </Grid>
        </Grid>
        
    );
}
