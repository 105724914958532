import React from 'react'
import { Routes, Route, Navigate  } from 'react-router-dom'
import './App.css'
import AdminCollections from './pages/Admin/Collections/AdminCollections'
import Login from './pages/Admin/Login/Login'
import Dashboard from './pages/Admin/Dashboard/Dashboard'
import AdminProducts from './pages/Admin/Products/AdminProducts'
import AdminProfile from './pages/Admin/Profile/AdminProfile'
import Collections from './pages/User/Collections/Collections'
import CollectionsList from './pages/User/CollectionsList/CollectionsList'
import Home from './pages/User/Home/Home'
import Product from './pages/User/Product/Product'
import Terms from './pages/User/Terms/Terms'
import {router} from './routePaths/routePaths'
import Contact from './pages/User/Contact/Contact'
import MenuBar from './layout/MenuBar'
import Wrapper from './layout/wrapper'
import AboutUs from './pages/User/AboutUs/AboutUs'
import AdminSublimations from './pages/Admin/Sublimations/AdminSublimations'
import SublimationsList from './pages/User/Sublimations/SublimationsList'
import SublimationDetail from './pages/User/SublimationDetail/SublimationDetail'

const App = () => {
  const PrivateRoute = ({ children}) => {
    const isAdmin = JSON.parse(localStorage.getItem('isLogged'));
    if(isAdmin !== null){
      if (isAdmin.user_type === 'admin' ) {
        return children
      } 
    }
      
    return <Navigate to={router.admin} />
  }
  function MissingRoute() {
    return < Navigate to={{pathname: router.home}} />
  }

  return (
    <Routes>
      <Route exact path={router.admin} element={<Login/>}/>
      <Route path={'/'} element={<Wrapper/>}>
        <Route exact path={router.home} element={<Home/>}/>
        <Route exact path={router.collections} element={<Collections/>}/>
        <Route exact path={router.collectionsList+'/:id'} element={<CollectionsList/>}/>
        <Route exact path={router.product+'/:id'} element={<Product/>}/>
        <Route exact path={router.sublimations} element={<SublimationsList/>}/>
        <Route exact path={router.sublimations+'/:id'} element={<SublimationDetail/>}/>
        <Route exact path={router.contact} element={<Contact/>}/>
        <Route exact path={router.aboutUs} element={<AboutUs/>}/>
      </Route>
      <Route path={router.admin} element={
        <PrivateRoute>
          <MenuBar/>
        </PrivateRoute>
      }>
        <Route exact path={router.dashboard} element={<Dashboard/>}/>
        <Route exact path={router.adminCollections} element={<AdminCollections/>}/>
        <Route exact path={router.adminProducts} element={<AdminProducts/>}/>
        <Route exact path={router.adminSubmlimation} element={<AdminSublimations/>}/>
        <Route exact path={router.adminProfile} element={<AdminProfile/>}/>
      </Route>
      
      <Route path="*" element={<MissingRoute/>} />
    </Routes>
  );
}

export default App;