import { AccountBoxOutlined, Category, CategoryOutlined, ColorLensOutlined, DashboardOutlined, Inventory2Outlined, Public } from "@mui/icons-material";

export const router = {
  admin: '/admin',
  login: '/login',
  signup: '/signup',
  termsOfService: '/terms-of-service',
  privacyPolicy: '/privacy-policy',
  aboutUs: '/about-us',
  profile: '/profile',
  history: '/order-history',
  home: '/',
  brands: '/brands',
  collections: '/collections',
  collectionsList: '/collections',
  sublimations: '/sublimations',
  product: '/product',
  cart: '/cart',
  contact: '/contact',
  checkout: '/checkout',
  dashboard: '/admin/dashboard',
  adminCollections: '/admin/collections',
  adminBrands: '/admin/brands',
  adminProducts: '/admin/products',
  adminSubmlimation: '/admin/sublimations',
  adminOrders: '/admin/orders',
  adminProfile: '/admin/profile',
  adminSize: '/admin/size',
  adminCategory: '/admin/category',
  adminUsers: '/admin/users',
  adminSubscriptions: '/admin/subscriptions',
};

export const adminRoutes = [
  {
    parent: {
      title: 'Website',
      path: router.home,
      icon: <Public />
    },
    child: []
  },
  {
    parent: {
      title: 'Dashboard',
      path: router.dashboard,
      icon: <DashboardOutlined />
    },
    child: []
  },
  {
    parent: {
      title: 'Profile',
      path: router.adminProfile,
      icon: <AccountBoxOutlined />
    },
    child: []
  },
  {
    parent: {
      title: 'Collections',
      path: router.adminCollections,
      icon: <CategoryOutlined />
    },
    child: []
  },
  {
    parent: {
      title: 'Products',
      path: router.adminProducts,
      icon: <Inventory2Outlined />
    },
    child: []
  },
  {
    parent: {
      title: 'Sublimations',
      path: router.adminSubmlimation,
      icon: <ColorLensOutlined />
    },
    child: []
  },
]
