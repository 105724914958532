import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import {
    Paper, Button, Divider, Grid, Typography, Avatar,
    ListItemButton, List, ListItemText, Collapse,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Facebook,Twitter,Instagram,Pinterest} from '@mui/icons-material';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
  } from "react-image-magnifiers";
import Carousel from "react-multi-carousel";
import {router} from "../../../routePaths/routePaths";
import logoSpinner from "../../../assets/images/logo/logo_spinner.png";

const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

export default function SublimationDetailView(props) {
    let navigate = useNavigate();
    const [image, setImage] = useState("");
    const [isOpen, setIsOpen] = useState([
        {id:1, isOpen: true},
        {id:2, isOpen: false},
        {id:3, isOpen: false},
        {id:4, isOpen: false},
        {id:5, isOpen: false},
        {id:6, isOpen: false},
    ])

    const handleClick = (item) =>{
        setIsOpen(isOpen.map(e => e.id === item.id ? ({...e, isOpen: !e.isOpen} ) : (e)))
        }
    useEffect(async()=>{
        setImage(props.sublimationDetail?.sub_image[0])
    },[])

    return (
        <>
            <Grid container spacing={3} sx={{py: 5, placeContent: "center", bgcolor: "#6d549638", height: (props.sublimationDetail.length === 0) ? '100vh' : ''}}>
                <Grid item xs={12} align="center">
                    <Typography variant="h4" sx={{color: 'primary.main', fontWeight: 'bold', textTransform: "uppercase"}}>
                    {props.sublimationDetail?.sub_name} - sublimation
                    </Typography>
                </Grid>
                <Grid item xs={12} align="center">
                    <Divider sx={{mb:3, color: 'primary.main'}}><img src={logoSpinner} width="50px" alt='loading'/></Divider>
                </Grid>
                <Grid item xs={10} align="center">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={12} md={8} lg={8} align="center">
                            <Grid container spacing={3}>
                                <Grid item xs={12} sx={12} md={3} lg={3} align="center">
                                    <ul className="image-avatar">
                                        {props.sublimationDetail?.sub_image !== undefined && props.sublimationDetail?.sub_image.map((x,index)=>{
                                            return(
                                                <>
                                                    <li onClick={()=>{props.onImageSelect(x)}}>
                                                        <Avatar
                                                            alt={index}
                                                            src={x}
                                                            sx={{ width: 80, height: 80, p: 0, border: (x == props.image) ? '1px solid #6d5496' : 0}}
                                                            variant="square"
                                                        />
                                                    </li>
                                                </>
                                            )
                                        })}
                                    </ul>
                                </Grid>
                                {props.image !== "" && <Grid item xs={12} sx={12} md={9} lg={9} align="center">
                                    <GlassMagnifier
                                        imageSrc={props.image}
                                        imageAlt="Loading"
                                        magnifierBorderColor="rgba(109,84,150,1)"
                                        magnifierSize="30%"
                                        // square={true}
                                        // onImageLoad={props.image}
                                        // style={{width: '391px', height: '278px'}}
                                    />
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={12} md={4} lg={4} align="center">
                            <Typography variant="h5" sx={{color: 'primary.main', fontWeight: 'bold'}}>
                                {props.sublimationDetail?.sub_name}
                            </Typography>
                            <Paper elevation={1} sx={{mt:2}}>
                                <ListItemButton onClick={() => handleClick(isOpen[0])}>
                                    <ListItemText primary={'Detail'} />
                                    {isOpen[0].isOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={isOpen[0].isOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ul style={{listStyle: 'none', margin: 0, padding: 0, fontSize: '13px'}}>
                                                <li style={{whiteSpace: 'pre-line'}}>{props.sublimationDetail?.sub_description}</li>
                                            </ul>
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                                <ListItemButton onClick={() => handleClick(isOpen[2])}>
                                    <ListItemText primary={'Social Media'} />
                                    {isOpen[2].isOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={isOpen[2].isOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItemButton>
                                            <a href='https://www.facebook.com/105325192225782' target={'_blank'} style={{color:'black'}}>
                                                <Facebook/>
                                            </a>
                                            <a href='https://instagram.com/champfitter?igshid=YmMyMTA2M2Y=' target={'_blank'} style={{color:'black'}}>
                                                <Instagram/>
                                            </a>
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} align="center">
                    <Divider sx={{mb:3, color: 'primary.main'}}><img src={logoSpinner} width="50px" alt='loading'/></Divider>
                </Grid>
                <Grid item xs={10} align="center">
                    {props.sublimations.length > 0 && <Paper elevation={0} square sx={{bgcolor: "#dfd9e8"}}>
                        <Carousel 
                            autoPlay={false}
                            responsive={responsive}>
                                {props.sublimations.map((y,index)=>{
                                    return(
                                        <Paper variant="outlined" square onClick={()=>navigate(router.sublimations+"/"+y.id)} sx={{mx: 1}}>
                                            <Paper 
                                                elevation={0}
                                                sx={{cursor: 'pointer', height: '250px', overflow: 'hidden', p: 0}} 
                                                square>
                                                <img src={JSON.parse(y.sub_image)[0]} width='100%' alt={index}/>
                                            </Paper>
                                            <Grid container spacing={2} sx={{py:2}}>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" sx={{color: 'primary.main'}}>
                                                        {y.sub_name}
                                                    </Typography>
                                                    <Divider sx={{bgcolor: 'secondary.main', width: '10%', borderBottomWidth: 'medium'}}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button 
                                                        className="btn-yellow" 
                                                        size="small"
                                                        sx={{px:2}}
                                                        onClick={()=>navigate(router.sublimations+"/"+y.id)}>
                                                        see detail
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    )
                                })}
                        </Carousel>
                    </Paper>}
                </Grid>
            </Grid>
        </>
        
    );
}
