import React, { useState, useEffect } from "react";
import commonService from "../../../../services/common-service";
import FooterView from "./FooterView";

export default function Footer() {
    const [collections, setCollections] = useState([])
    
    
    const getRecord=async()=>{
        const res = await commonService.get_record({table_name: 'collections'})
        setCollections(res.data);
    }
    
    useEffect(()=>{
        getRecord();
    },[])
    return (
        <div>
            <FooterView
                collections = {collections}
            />
        </div>
    );
}
