import React from "react";
import { alpha, styled } from '@mui/material/styles';
import { 
    Button, Typography, Table, TableBody, TableContainer,
    TableHead, TableRow, Paper, TablePagination, Switch,
    Collapse, IconButton, InputBase, Grid
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { DeleteForeverOutlined, EditOutlined, KeyboardArrowDown, KeyboardArrowUp, Search } from "@mui/icons-material";


const SearchBar = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        
        border: '1px solid #c9c6c6',
        borderRadius: '5px'
    },
  }));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const GoldSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#c6a215',
      '&:hover': {
        backgroundColor: alpha('#c6a215', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#c6a215',
    },
  }));  

export default function SublimationsView(prop) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = React.useState('');

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    
    return (
        <Grid container spacing={3} sx={{py: '100px', placeContent: "center", height: '92vh', bgcolor: '#dfd9e8'}}>
            <Grid item xs={6} align="start">
                <Typography variant="subtitle1" color="primary">
                    SUBLIMATIONS
                </Typography>
            </Grid>
            <Grid item xs={6} align="end">
                <Button 
                    type="button"
                    className='btn-yellow'
                    sx={{minWidth:110}}
                    onClick={prop.handleOpen}
                >
                        Add +
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} align="start">
                <SearchBar>
                    <SearchIconWrapper>
                        <Search />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        sx={{width: '100%'}}
                        inputProps={{ 'aria-label': 'search' }}
                        value={prop.searched}
                        onChange={(e) => prop.requestSearch(e.target.value)}
                    />
                </SearchBar>
            </Grid>
            <Grid item xs={12} align="center">
                <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden', bgcolor: '#f0eff3' }}>
                    <TableContainer sx={{ height: '65vh' }}>
                        <Table aria-label="collapsible table" size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell sx={{py: '16px'}}>Name</StyledTableCell>
                                    <StyledTableCell>Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {prop.sublimations
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    let images = JSON.parse(row.sub_image);
                                    return (
                                        <>
                                            <TableRow key={row.name}>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => {
                                                            // if(open !== row.id){prop.getSublimationDetail(row.id)};
                                                            setOpen((row.id === open ? "" : row.id))
                                                        }}
                                                    >
                                                        {open === row.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    {row.sub_name}
                                                </TableCell>
                                                <TableCell >
                                                    <Button 
                                                        type="button" 
                                                        onClick={()=>prop.delItem(row.id)} 
                                                        sx={{color: '#bc0000', background: '#bc00002e', p: '2px', minWidth: 0, m: 1}}
                                                    >
                                                        <DeleteForeverOutlined />
                                                    </Button>
                                                    <Button
                                                        type="button" 
                                                        onClick={()=>{prop.editItem(row);setOpen("")}}
                                                        sx={{color: '#027c92', background: '#027c924d', p: '2px', minWidth: 0, m: 1}}
                                                    >
                                                        <EditOutlined/>
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                                                        <Paper elevation={1} sx={{ mx: 1, my: 3, bgcolor: '#f0eff3'}}>
                                                            <Grid container spacing={3} sx={{ placeContent: "center"}}>
                                                                <Grid item xs={12} sm={12} md={6} lg={6} align="start">
                                                                    <Paper elevation={0} sx={{ margin: 1, bgcolor: '#f0eff3'}}>
                                                                        <Table size="small" aria-label="purchases">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <StyledTableCell colSpan={2}>Detail</StyledTableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell>
                                                                                        Description
                                                                                    </TableCell>
                                                                                    <TableCell sx={{whiteSpace: 'pre-line'}}>
                                                                                        {row.sub_description}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Paper>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={6} lg={6} align="start">
                                                                    <Grid container spacing={3} sx={{ placeContent: "center"}}>
                                                                            {images.map(x=>{
                                                                                return(
                                                                                    <Grid item xs={6} align="center">
                                                                                        <img src={x} width='100px' height={'100px'} alt="loading"/>
                                                                                    </Grid>
                                                                                )
                                                                            })}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Paper>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100, { label: 'All', value: -1 }]}
                        component="div"
                        count={prop.sublimations.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        SelectProps={{
                            MenuProps: { classes: "selectDropdown" }
                        }}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}
