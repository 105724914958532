import React, {useState, useEffect} from "react";
import commonHelper from "../../../helper/common_helper";
import productsService from "../../../services/products-service";
import commonService from "../../../services/common-service";
import ConfirmDiallog from "../../../shared/ConfirmDiallog";
import SnackAlert from "../../../shared/SnackAlert";
import Spinner from "../../../shared/Spinner";
import AddProduct from "./AddProduct";
import ProductsView from "./ProductsView";

export default function AdminProducts() {
    const [spinner, setSpinner] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');
    const [btn, setBtn] = React.useState('Add Product');
    const [editId, setEditId] = React.useState();
    const [inputField, setInputField] = useState({});
    const [selectedCol, setSelectedCol] = useState(null);
    const [images, setImages] = useState([]);
    const [productDetail, setProductDetail] = useState([]);
    const [products, setProducts] = useState([]);
    const [searched, setSearched] = useState("");
    const [rows, setRows] = useState([]);
    const [collections, setCollections] = useState([]);


    useEffect(()=>{
        getProducts();
    },[])

    const getProducts=async() => {
        setSpinner(true);
        const res = await productsService.getProducts();
        setProducts(res.data.products);
        setRows(res.data.products);
        setCollections(res.data.collections);
        setSpinner(false);
    }

    
    const handleChange=(e) => {    
        setInputField({...inputField, [e.target.name]: e.target.value});
    }

    const handleSubmit=async(e) => {
        e.preventDefault();
        setSpinner(true);
        let formData = new FormData();
        if(btn === 'Add Product'){
            if(images.length === 0){
                setMsg('Please Select image');
                setSeverity('error');
                setAlert(true);
                return;
            }
            inputField['collection_id'] = selectedCol.id;
            formData = commonHelper.createFormData(formData,inputField);
            for (let i = 0; i < images.length; i++) {
                formData.append(`pro_image[${i}]`, images[i])
            }
            const res = await productsService.addProduct(formData);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setProducts(res.data);
                setRows(res.data);
                Object.keys(inputField).forEach(key => delete inputField[key]);
                setImages([]);
                setOpen(false);
                setMsg('Product added successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }else{
            let obj = {};
            obj['id'] = editId;
            obj['pro_name'] = inputField.pro_name;
            obj['pro_description'] = inputField.pro_description;
            obj['collection_id'] = selectedCol.id;
            formData = commonHelper.createFormData(formData,obj);
            for (let i = 0; i < images.length; i++) {
                formData.append(`pro_image[${i}]`, images[i])
            }
            const res = await productsService.editProduct(formData);
            if(res.data.error){
                setMsg(res.data.message)
                setSeverity('error')
                setAlert(true)
            }else{
                setProducts(res.data);
                setRows(res.data);
                Object.keys(inputField).forEach(key => delete inputField[key]);
                setImages([]);
                setOpen(false);
                setMsg('Product updated successfuly')
                setSeverity('success')
                setAlert(true)
            }
        }
        setSelectedCol(null);
        setBtn('Add Product')
        setSpinner(false);
    }

    const resetFields = () => {
        Object.keys(inputField).forEach(key => delete inputField[key]);
        setImages([]);
        setSelectedCol(null);
    }

    const delItem=async()=>{
        setSpinner(true);
        const res = await commonService.del_record({id: editId, table_name: 'products'});
        if(res.data.status){
            setProducts(products.filter(function( obj ) {
                return obj.id !== editId;
            }))
            setRows(products.filter(function( obj ) {
                return obj.id !== editId;
            }));
        }
        setSpinner(false);
        setOpenDialog(false);
        setMsg('Product deleted successfuly')
        setSeverity('success')
        setAlert(true)
    }

    const editItem=async(item)=>{
        console.log(item)
        setSpinner(true);
        const res = await productsService.getProductDetail({id: item.id});
        let collection = collections.filter(x => x.col_name === res.data[0].col_name);
        setSelectedCol(collection[0]);
        setInputField(res.data[0]);
        setOpen(true);
        setSpinner(false);
        setBtn('Update Product');
        setEditId(item.id);
    }


    const fileSelectedHandler = (e) => {
        setImages(e.target.files)
    }

    const requestSearch = (searchedVal) => {
        const filteredRows = rows.filter((row) => {
          return row.pro_name.toLowerCase().includes(searchedVal.toLowerCase()) 
          || row.col_name.toLowerCase().includes(searchedVal.toLowerCase())
          || row.pro_description.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setSearched(searchedVal);
        setProducts(filteredRows);
    };

    return (
        <div>
            <ProductsView 
                products = {products}
                inputField = {inputField}
                searched = {searched}

                handleOpen = {() => {setOpen(true); resetFields()}}
                delItem = {(id) => {setEditId(id); setOpenDialog(true)}}
                editItem = {(item) => editItem(item)}
                requestSearch = {(e)=>requestSearch(e)}
            />
            <AddProduct
                collections = {collections}
                selectedCol = {selectedCol}
                open = {open}
                inputField = {inputField}
                btn = {btn}
                
                fileSelectedHandler = {(e)=>fileSelectedHandler(e)}
                setSelectedCol = {(e)=>setSelectedCol(e)}
                handleClose = {() => {setOpen(false); setBtn('Add Product')}}
                handleChange = {(e)=>handleChange(e)}
                handleSubmit = {(e)=>handleSubmit(e)}
            />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert 
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
            <ConfirmDiallog
                openDialog = {openDialog}

                closeDialog = {()=>setOpenDialog(false)}
                delItem = {()=>delItem()}
            />
        </div>
    );
}
