import React from "react";
import { useNavigate } from "react-router-dom";
import {
    Box, Button, Paper, Grid, Typography, Divider
} from '@mui/material';
import {Key, LightbulbOutlined, SpeedOutlined, StarsOutlined} from '@mui/icons-material';
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import {router} from "../../../routePaths/routePaths";
import Logo from "../../../assets/images/logo/logo.png";

export default function HomeView(props) {
    let navigate = useNavigate()
    return (
        <>
            <Slider className="slider-wrapper">
                {props.homeRecord?.collections?.map((item, index) => (
                    <div
                        key={index}
                        className="slider-content"
                        style={{ background: `url('${item.col_image}') no-repeat center center` }}
                    >
                        <img src={Logo} width="70px" style={{marginTop: "30px"}} />
                        <div className="inner">
                            <h1>{item.col_name}</h1>
                            <p></p>
                            <Button className="btn-yellow" onClick={()=>navigate(router.collections+'/'+item.id)} size="large" sx={{fontWeight: 'bold'}}>
                                VIEW COLLECTION
                            </Button>
                        </div>
                    </div>
                ))}
            </Slider>
            <Grid container spacing={3} sx={{py: 5, placeContent: "center", bgcolor: "#6d549638"}}>
                <Grid item xs={12} align="center">
                    <Key sx={{color: 'primary.dark', opacity: '0.5'}} />
                    <Typography variant="subtitle2" sx={{color: 'primary.main'}}>
                        HERE WE ARE
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 'bold', color: 'primary.dark'}}>
                        WELCOME!
                    </Typography>
                </Grid>
                <Grid item xs={10} align="center">
                    <Slider className="slider-side">
                        {props.homeRecord?.products?.map((item, index) => (
                            <Grid container spacing={0} sx={{my: 2, bgcolor: 'primary.dark'}}>
                                <Grid item xs={12} sm={12} md={6} lg={6} align="center">
                                    <img src={JSON.parse(item.pro_image)[0]} width="100%" height="100%"/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} align="left" alignSelf={"center"} px={2}>
                                    <div className="inner">
                                        <h1>{item.pro_name}</h1>
                                        <Divider sx={{bgcolor: 'secondary.main', width: '20%', borderBottomWidth: 'medium'}}/>
                                        <p>{item.col_name}</p>
                                        <Button className="btn-yellow" onClick={()=>navigate(router.product+'/'+item.id)} size="large" sx={{fontWeight: 'bold'}}>
                                            VIEW PRODUCT
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        ))}
                    </Slider>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{py: 5, placeContent: "center"}}>
                <Grid item xs={10} align="center">
                    <Grid container spacing={3} sx={{my: 2, placeContent: "center"}}>
                        <Grid item xs={12} align="center">
                            <Key sx={{color: 'primary.dark', opacity: '0.5'}} />
                            <Typography variant="subtitle2" sx={{color: 'primary.main'}}>
                                ABOUT US
                            </Typography>
                            <Typography variant="h4" sx={{fontWeight: 'bold', color: 'primary.dark'}}>
                                WHAT WE DO
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} align="center">
                            <LightbulbOutlined color="primary" fontSize="large"/>
                            <Typography variant="h5" sx={{color: 'primary.main'}}>
                                Our Mission
                            </Typography>
                            <Typography variant="subtitle2" sx={{color: 'primary.main'}}>
                                To exceed our customer expectations by providing high-quality 
                                solutions through custom design, 
                                innovation, on-time delivery, cost-effectiveness, 
                                support and continuously improving manufacturing 
                                processes.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} align="center">
                            <SpeedOutlined color="primary" fontSize="large"/>
                            <Typography variant="h5" sx={{color: 'primary.main'}}>
                                Our Capabilities
                            </Typography>
                            <Typography variant="subtitle2" sx={{color: 'primary.main'}}>
                                Our products are completely manufactured in-house from Knitting 
                                the fabric to Packing the final product. this allows us to have 
                                complete authority on quality control and delivery timelines as 
                                well as offering competitive prices for our products.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} align="center">
                            <StarsOutlined color="primary" fontSize="large"/>
                            <Typography variant="h5" sx={{color: 'primary.main'}}>
                                Our Vision
                            </Typography>
                            <Typography variant="subtitle2" sx={{color: 'primary.main'}}>
                                To work with the coherence of renowned global Brands, 
                                organizations, and entrepreneurs. Build trustworthy 
                                long term business relationships by providing on-demand 
                                custom high-quality sportswear.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>     
            </Grid>
            <Grid sx={{py: 3, placeContent: "center", height: '80vh'}}>
                {props.homeRecord?.products !== undefined && props.homeRecord?.products.length > 0 &&
                <Grid item xs={12} align="center"
                    sx={{
                        background: `url('${JSON.parse(props.homeRecord?.products[props.homeRecord?.products?.length-1].pro_image)[0]}') no-repeat`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '100%',
                        }}>
                    <Grid item xs={12} sx={{
                        height: '100%',
                        placeContent: "center",
                        display: 'flex',
                        background: 'linear-gradient(#6d5496a8, #6d5496a8)',
                        alignItems: 'center'}}>
                        <Typography variant="h3" sx={{color: '#fff', fontWeight: 'bold'}}>
                            We work directly with you to identify the right species for your needs!
                        </Typography>
                    </Grid>
                </Grid>}
            </Grid>
            <Grid container spacing={3} sx={{py: 5, placeContent: "center", bgcolor: "#6d549638"}}>
                <Grid item xs={12} align="center">
                    <Key sx={{color: 'primary.dark', opacity: '0.5'}} />
                    <Typography variant="subtitle2" sx={{color: 'primary.main'}}>
                        SHOP
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 'bold', color: 'primary.dark'}}>
                        PRODUCTS
                    </Typography>
                </Grid>
                <Grid item xs={10} align="center">
                    <Grid container spacing={3}>
                        {props.homeRecord?.products?.map((x,index)=>{
                            if(index < 4){
                                return(
                                    <Grid item xs={12} sm={12} md={3} lg={3} align="center">
                                        <Paper variant="outlined" square onClick={()=>navigate(router.product+"/"+x.id)}>
                                            <Paper 
                                                elevation={0}
                                                sx={{cursor: 'pointer', height: '250px', overflow: 'hidden', p: 0}} 
                                                square>
                                                <img src={JSON.parse(x.pro_image)[0]} width='100%'alt={index}/>
                                            </Paper>
                                            <Grid container spacing={2} sx={{py:2}}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" sx={{color: 'primary.main'}}>
                                                        {x.col_name}
                                                    </Typography>
                                                    <Divider sx={{bgcolor: 'secondary.main', width: '10%', borderBottomWidth: 'medium'}}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" sx={{color: 'primary.dark', fontWeight: 'bold'}}>
                                                        {x.pro_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button 
                                                        className="btn-yellow" 
                                                        size="small"
                                                        sx={{px:2}}
                                                        onClick={()=>navigate(router.product+'/'+x.id)}>
                                                        see detail
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                )
                            }
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} align="center">
                    <Button 
                        className="btn-blue"
                        sx={{px:5, py:1, fontWeight: 'bold'}}
                        onClick={()=>navigate(router.collectionsList)}
                        >VIEW ALL</Button>
                </Grid>
            </Grid>
            <Grid container spacing={3} sx={{py: 5, placeContent: "center", bgcolor: "#6d549638"}}>
                <Grid item xs={12} align="center">
                    <Key sx={{color: 'primary.dark', opacity: '0.5'}} />
                    <Typography variant="subtitle2" sx={{color: 'primary.main'}}>
                        ORDER NOW
                    </Typography>
                    <Typography variant="h4" sx={{fontWeight: 'bold', color: 'primary.dark'}}>
                        SUBLIMATIONS
                    </Typography>
                </Grid>
                <Grid item xs={10} align="center">
                    <Grid container spacing={3}>
                        {props.homeRecord?.sublimations?.map((x,index)=>{
                            if(index < 4){
                                return(
                                    <Grid item xs={12} sm={12} md={3} lg={3} align="center">
                                        <Paper variant="outlined" square onClick={()=>navigate(router.sublimations+"/"+x.id)}>
                                            <Paper 
                                                elevation={0}
                                                sx={{cursor: 'pointer', height: '250px', overflow: 'hidden', p: 0}} 
                                                square>
                                                <img src={JSON.parse(x.sub_image)[0]} width='100%'alt={index}/>
                                            </Paper>
                                            <Grid container spacing={2} sx={{py:2}}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" sx={{color: 'primary.main'}}>
                                                        {x.sub_name}
                                                    </Typography>
                                                    <Divider sx={{bgcolor: 'secondary.main', width: '10%', borderBottomWidth: 'medium'}}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button 
                                                        className="btn-yellow" 
                                                        size="small"
                                                        sx={{px:2}}
                                                        onClick={()=>navigate(router.sublimations+'/'+x.id)}>
                                                        see detail
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                )
                            }
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} align="center">
                    <Button 
                        className="btn-blue"
                        sx={{px:5, py:1, fontWeight: 'bold'}}
                        onClick={()=>navigate(router.sublimations)}
                        >VIEW ALL</Button>
                </Grid>
            </Grid>
        </>
    );
}
