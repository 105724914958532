import React, {useState} from "react";
import commonService from "../../../services/common-service";
import SnackAlert from "../../../shared/SnackAlert";
import Spinner from "../../../shared/Spinner";
import Footer from "../Common/Footer/Footer";
import Header from "../Common/Header/Header";
import ContactView from "./ContactView";

export default function Contact() {
    const color = '#a2a2a2';
    const [inputField, setInputField] = useState({});
    const [spinner, setSpinner] = React.useState(false);
    const [alert, setAlert] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [severity, setSeverity] = React.useState('');

    const handleChange=(e)=>{
        setInputField({...inputField, [e.target.name]: e.target.value});
    }
    const handleSubmit=async(e)=>{
        e.preventDefault();
        // setSpinner(true);
        const res = await commonService.sendMail(inputField);
        if(res.data.error){
            setMsg('Something went wrong.Please try again later');
            setSeverity('error')
            setAlert(true)
        }else{
            setMsg("Your email is received. We'll get back to you soon");
            setSeverity('success');
            setAlert(true);
        }
        setSpinner(false);

    }
    return (
        <>
            <ContactView
                inputField = {inputField}

                handleChange = {(e)=>handleChange(e)}
                handleSubmit = {(e)=>handleSubmit(e)}
            />
            <Spinner
                spinner = {spinner}
            />
            <SnackAlert
                msg={msg}
                alert={alert}
                severity={severity}

                closeAlert={()=>setAlert(false)}
            />
        </>
    );
}
