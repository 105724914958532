import * as React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box, 
  Button,
  ListItemButton,
  Typography,
  Drawer, 
  CssBaseline, 
  AppBar as MuiAppBar, 
  Toolbar, 
  List, 
  Divider, 
  IconButton,
  Grid,
  ListItemText,
  MenuItem,
  Menu
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft,
  ChevronRight,
  AccountCircle,
} from '@mui/icons-material';
import logo from "../../../assets/images/logo/logo.png";
import {adminRoutes, router} from '../../../routePaths/routePaths';

const drawerWidth = 250;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  let navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    localStorage.removeItem("isLogged");
    navigate(router.admin);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" open={open} >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }), }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Welcome
          </Typography>
          <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={()=>navigate(router.adminProfile)}>Profile</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        PaperProps={{
          sx: {
            backgroundColor: "#F0F2F5 !important",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Grid container spacing={3} sx={{py: 1, placeContent: "center"}}>
            <Grid item xs={8} align="end">
              <img src={logo} width='50px'/>
            </Grid>
            <Grid item xs={4} align="end">
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </Grid>
          </Grid>
        </DrawerHeader>
        <Divider />
        {adminRoutes.map(route=>{
          return(
            <NavLink
              to={route.parent.path}
              style={({ isActive }) => (isActive ?
                {color: '#fca114', textDecoration: 'none', borderLeft: '5px solid'} : {color: '#6d5496', textDecoration: 'none'})}
            >
                <ListItemButton>
                    {route.parent.icon} &nbsp;
                    <ListItemText primary={route.parent.title}/>
                </ListItemButton>
            </NavLink>
          )
        })}
      </Drawer>
      {/* <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: '#fff'}}>
        <Toolbar variant="dense" sx={{alignSelf: 'center'}}>
          <Box sx={{ flexGrow: 1, display: { md: 'flex', color: 'gray' } }}>
            © {new Date().getFullYear()} Canada-wide-parking
          </Box>
        </Toolbar>
      </AppBar> */}
    </Box>
  );
}
